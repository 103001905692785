<template>
	<div class="commonProblem">
		<div class="commonProblem__title-container">
			<span>首页 > </span>
			<span>常见问题</span>
		</div>
		<div class="commonProblem__container">
			<div>1、SMT能代工代料吗 ？</div>
			<div>里德可以承接PCB打板，BOM物料配单，SMT贴片加工，DIP插件等所有业务，一站式为客户解决样品打样及量产的所有业务。</div>
		</div>
		<div class="commonProblem__container">
			<div>2、发票可以累计吗？</div>
			<div>由于样品金额小、款数多，所以里德网平台采取累计说明、普票最低累计金额为200元，专票最低累计金额为1000元，当月提交的订单发票申请，由财务人员在月底20-25号统一开具。</div>
		</div>
		<div class="commonProblem__container">
			<div>3、文件上传后，是先审核，还是要付款后再审核？</div>
			<div>里德支持先审核后付款，也支持先付款后审核。当用户下单后，不管是否付款，审单人员都会审核订单并告知审核结果。</div>
		</div>
		<div class="commonProblem__container">
			<div>4、折扣券使用说明？</div>
			<div>1、折扣券不可以提现，不可与其它活动优惠重叠使用；2、折扣券不可用于特价板消费；3、折扣券金额不计入开票金额；折扣券金额不计入货物退款金额；单笔订单最多抵扣一张券。</div>
		</div>
		
		<el-pagination
				background
				layout="prev, pager, next, jumper"
				:total="90"
				style="text-align: right" />
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "@/styles/common.scss";

.commonProblem {
  padding: 40px gap();
  background-color: #fff;
}

.commonProblem__title-container {
	font-size: 14px;
	margin-bottom: 20px;
	
	span:nth-of-type(2) {
		color: #a6a6a6;
	}
}

.commonProblem__container {
	div:first-of-type {
		font-size: 20px;
		font-weight: bold;
		margin: 16px 0;
	}
	
	div:last-of-type {
		font-size: 16px;
		color: #494949;
		margin-bottom: 40px;
		text-indent: 2em;
	}
}
</style>
